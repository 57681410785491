import { fetchFromTimestream } from "api";
import { useAuthFetch } from "context/AuthContext";
import { Dayjs } from "utils/dayjs";

import { formatDate } from "./utils";

export const usePowerTimeseries = () => {
  const { authFetch } = useAuthFetch();

  const getFinalStorageData = (
    partnerId: string,
    siteId: string,
    start: Dayjs,
    end: Dayjs,
  ): Promise<{ x: number; y: number }[]> => {
    const formattedDayStart = formatDate(start.startOf("day"));
    const table = `"${partnerId}"."${siteId}_Data"`;
    const timeStart = formatDate(start);
    const timeEnd = formatDate(end);

    return fetchFromTimestream(
      `SELECT
        DATE_DIFF('minute', '${formattedDayStart}', TimeBin) / 60.0,
        SUM(soc) * 200.0 as TotalSoC
      FROM (
        SELECT
            bin(time, 15m) as TimeBin,
            DeviceName,
            soc,
            ROW_NUMBER() OVER (
                PARTITION BY DeviceName, bin(time, 15m) ORDER BY time DESC
            ) as rn
        FROM ${table} WHERE DeviceType = 'battery'
        AND time BETWEEN '${timeStart}' AND '${timeEnd}'
      ) as subquery WHERE rn = 1 GROUP BY TimeBin ORDER BY TimeBin`,
      authFetch,
    ).then((data) =>
      data.rows.map((bin) => {
        return {
          x: bin?.data[0].scalarValue,
          y: bin?.data[1].scalarValue,
        };
      }),
    );
  };

  const getFinalGenerationData = (
    partnerId: string,
    siteId: string,
    start: Dayjs,
    end: Dayjs,
  ): Promise<{ x: number; y: number }[]> => {
    const formattedDayStart = formatDate(start.startOf("day"));
    const table = `"${partnerId}"."${siteId}_Data"`;
    const timeStart = formatDate(start);
    const timeEnd = formatDate(end);

    return fetchFromTimestream(
      `SELECT
        DATE_DIFF('minute', '${formattedDayStart}', TimeBin) / 60.0,
        SUM(gridDrawOff) / 1000.0 as TotalSoC
      FROM (
        SELECT
            bin(time, 15m) as TimeBin,
            DeviceName,
            gridDrawOff,
            ROW_NUMBER() OVER (
                PARTITION BY DeviceName, bin(time, 15m) ORDER BY time DESC
            ) as rn
        FROM ${table} WHERE DeviceType = 'charger'
        AND time BETWEEN '${timeStart}' AND '${timeEnd}'
      ) as subquery WHERE rn = 1 GROUP BY TimeBin ORDER BY TimeBin`,
      authFetch,
    ).then((data) =>
      data.rows.map((bin) => {
        return {
          x: bin?.data[0].scalarValue,
          y: bin?.data[1].scalarValue,
        };
      }),
    );
  };

  const getFinalUsageData = (
    partnerId: string,
    siteId: string,
    start: Dayjs,
    end: Dayjs,
  ): Promise<{ x: number; y: number }[]> => {
    const formattedDayStart = formatDate(start.startOf("day"));
    const table = `"${partnerId}"."${siteId}_Data"`;
    const timeStart = formatDate(start);
    const timeEnd = formatDate(end);

    return fetchFromTimestream(
      `SELECT
        DATE_DIFF('minute', '${formattedDayStart}', TimeBin) / 60.0,
        SUM(chargeCutoff) / 1000.0 as TotalSoC
      FROM (
        SELECT
            bin(time, 15m) as TimeBin,
            DeviceName,
            chargeCutoff,
            ROW_NUMBER() OVER (
                PARTITION BY DeviceName, bin(time, 15m) ORDER BY time DESC
            ) as rn
        FROM ${table} WHERE DeviceType = 'charger'
        AND time BETWEEN '${timeStart}' AND '${timeEnd}'
      ) as subquery WHERE rn = 1 GROUP BY TimeBin ORDER BY TimeBin`,
      authFetch,
    ).then((data) =>
      data.rows.map((bin) => {
        return {
          x: bin?.data[0].scalarValue,
          y: bin?.data[1].scalarValue,
        };
      }),
    );
  };

  return {
    getFinalStorageData,
    getFinalGenerationData,
    getFinalUsageData,
  };
};
