import { SiteResponse, usePlacesApi } from "api/ingestion/places";
import { Thing, useThingsApi } from "api/ingestion/things";
import { DeviceDetail } from "components";
import { SingleCategoryFilter } from "components/controls/SingleCategoryFilter";
import { useEffect, useState } from "react";

import AddDeviceModal from "./AddDeviceModal";
import DevicesTable from './DevicesTable';
import { DevicesBySite } from "./DevicesBySite.tsx";
import { DevicesByType } from "./DevicesByType.tsx";
import { SelectedDeviceProvider, useSelectedDevice } from "context/SelectedDeviceContext.tsx";
import { SelectedTimeRangeProvider, TimeRangeSelector } from "context/SelectedTimeRangeContext.tsx";

const DeviceDetailWrapper = () => {
  const { selectedDevice, setSelectedDevice } = useSelectedDevice();
  return (
    <DeviceDetail
      selectedDevice={selectedDevice}
      setSelectedDevice={setSelectedDevice}
    />
  );
};

const PageContent = () => {
  const [viewMode, setViewMode] = useState('tile');
  const [showBySite, setShowBySite] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sites, setSites] = useState<SiteResponse[]>([]);
  const [deviceTypes, setDeviceTypes] = useState<string[]>([]);
  const [devices, setDevices] = useState<Thing[]>([]);
  const [selectedDeviceType, setSelectedDeviceType] = useState<string>('All');

  const { addDevice, getThingTypes, getThings } = useThingsApi();
  const { getSites } = usePlacesApi();

  useEffect(() => {
    const fetchData = async () => {
      const sites = await getSites();
      const allDeviceTypes = await getThingTypes();
      const allDevices = await getThings();
      setSites(sites);
      setDeviceTypes(['All', ...allDeviceTypes]);
      setDevices(allDevices);
    };

    fetchData();
  }, []);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleDeviceAdded = (siteId, deviceData) => {
    return addDevice(siteId, deviceData).then((result) => {
      if (result.success) {
        return { success: true };
      } else {
        return { success: false, error: result.error };
      }
    });
  };

  const filteredDevices = selectedDeviceType === 'All'
    ? devices
    : devices.filter(device => device.thingType === selectedDeviceType);

  return (
    <div className="flex flex-row w-full min-h-screen">
      <SelectedDeviceProvider>
        <div className="flex-1 h-screen overflow-y-auto">
          <div className="flex pt-4 pb-5 justify-between">
            <p className="text-heading1 text-space50">Devices</p>
            <div className="flex gap-2">
              <TimeRangeSelector />
              <SingleCategoryFilter
                options={["Tile View", "Table View"]}
                defaultSelected={viewMode === 'tile' ? "Tile View" : "Table View"}
                onChange={(value) => setViewMode(value === "Tile View" ? 'tile' : 'table')}
              />
              {viewMode === 'tile' && (
                <SingleCategoryFilter
                  options={["Group by Site", "Group by Type"]}
                  defaultSelected={showBySite ? "Group by Site" : "Group by Type"}
                  onChange={(value) => setShowBySite(value === "Group by Site")}
                />
              )}
              {viewMode === 'table' && (
                <SingleCategoryFilter
                  options={deviceTypes}
                  defaultSelected={selectedDeviceType}
                  onChange={setSelectedDeviceType}
                />
              )}
              <button
                onClick={handleOpenModal}
                className="px-3.5 py-2 rounded-full border border-space80 justify-end items-center gap-1 cursor-pointer hover:bg-gray95 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue90 focus:ring-blue90 flex"
              >
                <div className="text-space70 text-xs font-medium leading-[14px]">
                  + Add Device
                </div>
              </button>
              <AddDeviceModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                onDeviceAdded={handleDeviceAdded}
                sites={sites}
                deviceTypes={deviceTypes.filter(type => type !== 'All')}
              />
            </div>
          </div>

          {viewMode === 'tile' ? (
            showBySite ? (
              <DevicesBySite
                sites={sites}
                devices={devices}
                deviceTypes={deviceTypes}
              />
            ) : (
              <DevicesByType
                sites={sites}
                devices={devices}
                deviceTypes={deviceTypes}
              />
            )
          ) : (
            <DevicesTable devices={filteredDevices} />
          )}
        </div>
        <DeviceDetailWrapper />
      </SelectedDeviceProvider>
    </div>
  );
};

const DevicesPage = () => {
  return (
    <SelectedTimeRangeProvider>
      <PageContent />
    </SelectedTimeRangeProvider>
  );
};

export { DevicesPage };
