import { useAuthFetch } from "context/AuthContext";

import { BASE_URL, GenericAttributes } from "./common";

export type Thing = {
  thingId: string;
  thingName: string;
  thingType: string;
  thingDescription: string;
  model: string;
  isSimulated: boolean;
  longitude: number;
  latitude: number;
  altitude: number;
  integrationId: string;
  partnerId: string;
  siteId: string;
  levelId: string;
  attributes: GenericAttributes;
  createdAt: string;
  createdBy: string;
};

export const useThingsApi = () => {
  const { authFetch } = useAuthFetch();

  const getThings = async (): Promise<Thing[]> => {
    const url = `${BASE_URL}/things`;
    return await authFetch(url, {
      method: "GET",
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const getThingTypes = async (): Promise<string[]> => {
    const url = `${BASE_URL}/things/types`;
    return await authFetch(url, {
      method: "GET",
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const getThingsFromSite = async (siteId: string): Promise<Thing[]> => {
    const url = `${BASE_URL}/site/${siteId}/things`;
    return await authFetch(url, {
      method: "GET",
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const getThingsFromFleet = async (fleetId: string): Promise<Thing[]> => {
    const url = `${BASE_URL}/fleet/${fleetId}/things`;
    return await authFetch(url, {
      method: "GET",
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  }

  const getThingFromFleet = async (
    fleetId: string,
    thingId: string,
  ): Promise<Thing> => {
    const url = `${BASE_URL}/fleet/${fleetId}/thing/${thingId}`;
    return await authFetch(url, {
      method: "GET",
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  }

  const getThingFromSite = async (
    siteId: string,
    thingId: string,
  ): Promise<Thing> => {
    const url = `${BASE_URL}/site/${siteId}/thing/${thingId}`;
    return await authFetch(url, {
      method: "GET",
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const getThingsFromLevelGroup = async (
    siteId: string,
    levelGroupId: string,
  ): Promise<Thing[]> => {
    const url = `${BASE_URL}/site/${siteId}/levelGroup/${levelGroupId}/things?onlyIds=false`;
    return await authFetch(url, {
      method: "GET",
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const getThingsForLevel = async (
    siteId: string,
    levelId: string,
  ): Promise<Thing[]> => {
    const url = `${BASE_URL}/site/${siteId}/level/${levelId}/things?onlyIds=false`;
    return await authFetch(url, {
      method: "GET",
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const addDevice = async (siteId: string, deviceData: any) => {
    const addDeviceUrl = `${BASE_URL}/site/${siteId}/thing`;

    try {
      const response = await authFetch(addDeviceUrl, {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(deviceData),
      });

      if (response.status === 201) {
        return { success: true, message: "Device created successfully" };
      } else {
        const errorData = await response.json();
        console.error("Error creating site:", errorData);
        return { success: false, error: errorData };
      }
    } catch (error) {
      console.error("Error creating site:", error);
      return { success: false, error: error.message };
    }
  };

  return {
    getThings,
    getThingTypes,
    getThingsFromSite,
    getThingFromSite,
    getThingsFromFleet,
    getThingFromFleet,
    getThingsFromLevelGroup,
    getThingsForLevel,
    addDevice,
  };
};
