import { convertToTimeseries, mergeDatapoints, useDataApi } from "api/data.ts";
import { SiteResponse } from "api/ingestion/places";
import { Thing, useThingsApi } from "api/ingestion/things";
import { ChargerPie } from "components";
import { MicroHorizontalTimeseriesChart } from "components/charts/horizontalTimeSeries";
import { dayjs } from "utils/dayjs";



import { useEffect, useState } from "react";



import { ReactComponent as BatteriesIcon } from "images/icons/batteries.svg";
import { ReactComponent as ChargersIcon } from "images/icons/chargers.svg";
import { ReactComponent as GridsIcon } from "images/icons/grids.svg";
import { ReactComponent as DownTrendIcon } from "images/icons/trend/down.svg";
import { ReactComponent as NominalTrendIcon } from "images/icons/trend/nominal.svg";
import { ReactComponent as UpTrendIcon } from "images/icons/trend/up.svg";


// TODO: add meter as type with icon
const Header = ({
  shortCode,
  siteName,
  siteAddress,
  nBatteries,
  nChargers,
  nGrids,
  batteryAlert = "none",
  chargerAlert = "none",
  gridAlert = "none",
}: {
  shortCode: string;
  siteName: string;
  siteAddress: string;
  nBatteries: number;
  nChargers: number;
  nGrids: number;
  batteryAlert?: string;
  chargerAlert?: string;
  gridAlert?: string;
}) => {
  const colorizeAlert = (alert: string) => {
    if (alert === "critical") {
      return "bg-red50 text-white";
    }
    if (alert === "warning") {
      return "bg-yellow50 text-white";
    }
    return "text-space50";
  };
  return (
    <div className="self-stretch justify-between items-center inline-flex px-4 pt-4">
      <div className="justify-start items-center gap-4 flex">
        <div className="px-[11px] py-1.5 bg-gray95 rounded-sm justify-start items-center flex">
          <div className="text-space50 text-sm font-medium leading-tight">
            {shortCode}
          </div>
        </div>
        <div className="flex-col justify-start items-start inline-flex">
          <div className="text-space50 text-sm font-normal">{siteName}</div>
          <div className="text-space70 text-xs font-normal">{siteAddress}</div>
        </div>
      </div>
      <div className="justify-end items-center gap-1 flex">
        <div
          className={`px-2 py-1.5 rounded-sm justify-start items-start gap-0.5 ${colorizeAlert(
            batteryAlert,
          )}`}
        >
          <p className="text-sm font-medium">
            {nBatteries}{" "}
            <BatteriesIcon className="inline-block align-middle mb-1" />
          </p>
        </div>
        <div
          className={`px-2 py-1.5 rounded-sm justify-start items-start gap-0.5 ${colorizeAlert(
            chargerAlert,
          )}`}
        >
          <p className="text-sm font-medium">
            {nChargers}{" "}
            <ChargersIcon className="inline-block align-middle mb-1" />
          </p>
        </div>
        <div
          className={`px-2 py-1.5 rounded-sm justify-start items-start gap-0.5 ${colorizeAlert(
            gridAlert,
          )}`}
        >
          <p className="text-sm font-medium">
            {nGrids} <GridsIcon className="inline-block align-middle mb-1" />
          </p>
        </div>
      </div>
    </div>
  );
};

const Alert = ({
  alertText,
  timestamp,
  alertType,
}: {
  alertText: string;
  timestamp: string;
  alertType: string;
}) => {
  if (alertType === "warning") {
    return (
      <div className="self-stretch px-4 py-2 bg-yellow50 justify-between items-center inline-flex">
        <div className="text-white text-sm font-medium  leading-tight">
          {alertText}
        </div>
        <div className="opacity-70 text-yellow90 text-sm font-normal ">
          {timestamp}
        </div>
      </div>
    );
  }

  return (
    <div className="self-stretch px-4 py-2 bg-red50 justify-between items-center inline-flex">
      <div className="text-white text-sm font-medium leading-tight">
        {alertText}
      </div>
      <div className="opacity-70 text-red90 text-sm font-normal ">
        {timestamp}
      </div>
    </div>
  );
};

export const Trend = ({
  percent,
  decimalPlaces = 0,
}: {
  percent: number;
  decimalPlaces?: number;
}) => {
  const percentage = percent * 100;
  const getTrendIcon = (percentage: number) => {
    if (percentage >= 1) {
      return <UpTrendIcon />;
    }
    if (percentage <= -1) {
      return <DownTrendIcon />;
    }
    return <NominalTrendIcon />;
  };

  const getTrendColor = (percentage: number) => {
    if (percentage >= 1) {
      return "bg-green90 text-green40";
    }
    if (percentage <= -1) {
      return "bg-red90 text-red40";
    }
    return "border border-grey95 text-grey50";
  };

  if (percentage < 1 && percentage > -1) {
    return (
      <div
        className={`px-2 py-1.5 ${getTrendColor(
          percentage,
        )} rounded justify-start items-center gap-1 flex`}
      >
        <div className="text-caption font-bold uppercase">&ndash;</div>
      </div>
    );
  }

  return (
    <div
      className={`px-2 py-1.5 ${getTrendColor(
        percentage,
      )} rounded justify-start items-center gap-1 flex`}
    >
      <div className="text-caption font-bold uppercase">
        {Math.abs(percentage).toFixed(decimalPlaces)}%
      </div>
      <div className="text-caption uppercase">{getTrendIcon(percentage)}</div>
    </div>
  );
};

const TimeseriesPlaceholder = () => (
  <div className="p-3 h-[52px]">
      <p className="text-center">
        No data for the selected timeframe
      </p>
  </div>
);

const SiteTile = ({ site }: { site: SiteResponse }) => {
  const [things, setThings] = useState<Thing[]>([]);
  const [summary, setSummary] = useState<TimeseriesValue>();
  const [timeseries, setTimeseries] = useState<Timeseries>();

  const { getSummaryForSite, getTimeseriesForSite } = useDataApi();
  const { getThingsFromSite } = useThingsApi();
  useEffect(() => {
    const end = dayjs();
    const start = end.subtract(7, "day");

    getThingsFromSite(site.siteId)
      .then(setThings)
      .catch((e) =>
        console.error(`Unable to fetch things for ${site.siteId}`, e),
      );
    getSummaryForSite(site.siteId, start, end)
      .then((e) => mergeDatapoints(e.dataPoints))
      .then(setSummary)
      .catch((e) =>
        console.error(`Unable to fetch summary for ${site.siteId}`, e),
      );
    getTimeseriesForSite(site.siteId, start, end, "h", 2)
      .then(convertToTimeseries)
      .then(setTimeseries)
      .catch((e) =>
        console.error(`Unable to fetch timeseries for ${site.siteId}`, e),
      );
  }, [site.siteId]);

  const shortCode = site.siteName.substring(0, 3).toUpperCase();
  const siteName = site.siteName;
  const siteAddress = site.siteAddress;

  const nBatteries = things.filter(
    (thing) => thing.thingType === "Battery",
  ).length;
  const nChargers = things.filter(
    (thing) => thing.thingType === "Charger",
  ).length;
  const nMeters = things.filter((thing) => thing.thingType === "Meter").length;

  const stored = summary?.stored || 0;
  const drawn = summary?.fwd || 0;
  const discharged = summary?.discharged || 0;
  const demand = summary?.demand || 0;

  return (
    <div className="w-full mb-4 bg-white rounded-md shadow border border-zinc-300 flex-col justify-start items-start gap-4 inline-flex transition-transform hover:-translate-y-0.5 hover:shadow-md">
      <div className="self-stretch justify-between items-center inline-flex px-4 pt-4">
        <div className="justify-start items-center gap-4 flex">
          <div className="px-[11px] py-1.5 bg-gray95 rounded-sm justify-start items-center flex">
            <div className="text-space50 text-sm font-medium leading-tight">
              {shortCode}
            </div>
          </div>
          <div className="flex-col justify-start items-start inline-flex">
            <div className="text-space50 text-sm font-normal">{siteName}</div>
            <div className="text-space70 text-xs font-normal">{siteAddress}</div>
          </div>
        </div>
        <div className="justify-end items-center gap-1 flex">
          <div className="px-2 py-1.5 rounded-sm justify-start items-start gap-0.5 text-space50">
            <p className="text-sm font-medium">
              {nBatteries}{" "}
              <BatteriesIcon className="inline-block align-middle mb-1" />
            </p>
          </div>
          <div className="px-2 py-1.5 rounded-sm justify-start items-start gap-0.5 text-space50">
            <p className="text-sm font-medium">
              {nChargers}{" "}
              <ChargersIcon className="inline-block align-middle mb-1" />
            </p>
          </div>
          <div className="px-2 py-1.5 rounded-sm justify-start items-start gap-0.5 text-space50">
            <p className="text-sm font-medium">
              {nMeters} <GridsIcon className="inline-block align-middle mb-1" />
            </p>
          </div>
        </div>
      </div>
      <div className="self-stretch justify-center items-center gap-4 inline-flex px-4">
        <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
          <div className="self-stretch justify-start items-start gap-4 inline-flex">
            <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
              <div className="self-stretch justify-between items-center inline-flex">
                <div className="h-7 justify-start items-center gap-1 flex">
                  <div className="text-space50 text-xl font-medium leading-7">
                    {stored ? stored.toLocaleString() : "-"}
                  </div>
                  <div className="text-space70 text-xs font-normal">kwh</div>
                </div>
                <Trend percent={0} />
              </div>
              <div className="text-space70 text-xs font-normal">
                Currently Stored
              </div>
            </div>
            <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
              <div className="self-stretch justify-between items-center inline-flex">
                <div className="h-7 justify-start items-center gap-1 flex">
                  <div className="text-space50 text-xl font-medium leading-7">
                    {drawn ? drawn.toLocaleString() : "-"}
                  </div>
                  <div className="text-space70 text-xs font-normal">kwh</div>
                </div>
                <Trend percent={0} />
              </div>
              <div className="text-space70 text-xs font-normal">
                Drawn from Grid
              </div>
            </div>
          </div>
          <div className="self-stretch justify-start items-start gap-4 inline-flex">
            <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
              <div className="self-stretch justify-between items-center inline-flex">
                <div className="h-7 justify-start items-center gap-1 flex">
                  <div className="text-space50 text-xl font-medium leading-7">
                    {discharged ? discharged.toLocaleString() : "-"}
                  </div>
                  <div className="text-space70 text-xs font-normal">kwh</div>
                </div>
                <Trend percent={0} />
              </div>
              <div className="text-space70 text-xs font-normal">
                Discharged to Vehicles
              </div>
            </div>
            <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
              <div className="self-stretch justify-between items-center inline-flex">
                <div className="h-7 justify-start items-center gap-1 flex">
                  <div className="text-space50 text-xl font-medium leading-7">
                    {demand ? demand.toLocaleString() : "-"}
                  </div>
                  <div className="text-space70 text-xs font-normal">kwh</div>
                </div>
                <Trend percent={0} />
              </div>
              <div className="text-space70 text-xs font-normal">
                Forecasted Remaining Demand
              </div>
            </div>
          </div>
          {timeseries ? (
            <MicroHorizontalTimeseriesChart timeseries={timeseries} />
          ) : (
            <TimeseriesPlaceholder />
          )}
        </div>
      </div>
    </div>
  );
};

export { SiteTile };