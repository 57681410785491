import { Timeseries, convertToTimeseries, useDataApi } from "api/data.ts";
import { Thing } from "api/ingestion/things.ts";
import {
  BatterySummaryStats,
  ChargerSummaryStats,
  useBatteryTimeseries,
  useChargerTimeseries,
} from "api/timeseries";
import { useSelectedDevice } from "context/SelectedDeviceContext.tsx";
import { useSelectedTimeRange } from "context/SelectedTimeRangeContext.tsx";

import { useEffect, useState } from "react";

import { ReactComponent as BatteriesIcon } from "images/icons/batteries.svg";
import { ReactComponent as ChargersIcon } from "images/icons/chargers.svg";
import { ReactComponent as MeterIcon } from "images/icons/meter.svg";

import { useAuth } from "../../context/AuthContext";

enum SupportedDeviceType {
  Charger,
  Meter,
  Battery,
  SwapStation,
}
const getDeviceType = (thingType: string): SupportedDeviceType => {
  if (thingType == "Charger") {
    return SupportedDeviceType.Charger;
  } else if (thingType == "Meter") {
    return SupportedDeviceType.Meter;
  } else if (thingType == "Battery") {
    return SupportedDeviceType.Battery;
  } else if (thingType == "SwapStation") {
    return SupportedDeviceType.SwapStation;
  } else {
    throw new Error("Unsupported device type");
  }
};

enum Color {
  Blue = "blue",
  Space = "space",
  Green = "green",
  Yellow = "yellow",
  Red = "red",
  Gray = "gray",
  Low = "low",
  Medium = "medium",
  High = "high",
}

const colorToTailwindText = (color: Color) => {
  switch (color) {
    case Color.Blue:
      return "text-blue20";
    case Color.Space:
      return "text-space20";
    case Color.Green:
      return "text-green20";
    case Color.Yellow:
      return "text-yellow20";
    case Color.Red:
      return "text-red20";
    case Color.Gray:
      return "text-gray20";
    case Color.Low:
      return "text-blue10";
    case Color.Medium:
      return "text-blue10";
    case Color.High:
      return "text-blue10";
  }
};

const colorToTailwindBackground = (color: Color) => {
  switch (color) {
    case Color.Blue:
      return "bg-blue80";
    case Color.Space:
      return "bg-space80";
    case Color.Green:
      return "bg-green80";
    case Color.Yellow:
      return "bg-yellow80";
    case Color.Red:
      return "bg-red80";
    case Color.Gray:
      return "bg-gray97";
    case Color.Low:
      return "bg-blue90";
    case Color.Medium:
      return "bg-blue80";
    case Color.High:
      return "bg-blue70";
  }
};

const percentageToColor = (percentage: number) => {
  if (isNaN(percentage)) {
    return Color.Gray;
  }

  if (percentage < 0.2) {
    return Color.Low;
  } else if (percentage < 0.5) {
    return Color.Medium;
  } else {
    return Color.High;
  }
};

export const ProgressBar = ({
  value,
  percentage,
  unit,
  label,
  colorOverride = null,
  digits = 0,
}: {
  value: number;
  percentage: number;
  unit: string;
  label: string;
  colorOverride?: Color | null;
  digits?: number;
}) => {
  const formattedValue = value.toLocaleString("en-US", {
    maximumFractionDigits: digits,
  });

  const color = colorOverride ?? percentageToColor(percentage);

  // HACK: make the tailwind compiler to recognize that we need to generate these colors
  const backgroundColor = colorToTailwindBackground(color);
  const textColor = colorToTailwindText(color);

  return (
    <div className="relative w-full">
      <div className="z-0 absolute top-0 left-0 bottom-0 right-0">
        <div
          className={`h-full ${backgroundColor}`}
          style={{ width: `${Math.min(percentage, 1) * 100}%` }}
        ></div>
      </div>
      <div className="z-10 relative px-4 py-1 justify-between items-center flex">
        <div className={`${textColor} justify-start items-center gap-1 flex`}>
          <div className="text-body">{formattedValue}</div>
          <div className="text-[10px]">{unit}</div>
        </div>
        <div className="text-right text-[10px]">{label}</div>
      </div>
    </div>
  );
};

const DeviceTile = ({ device }: { device: Thing }) => {
  const deviceType = getDeviceType(device.thingType);
  switch (deviceType) {
    case SupportedDeviceType.Meter:
      return <MeterDeviceTile device={device} />;
    case SupportedDeviceType.SwapStation:
      return <SwapStationDeviceTile device={device} />;
    default:
      return <ChargerOrBatteryDeviceTile device={device} />;
  }
};

const SwapStationDeviceTile = ({ device }: { device: Thing }) => {
  const { selectedDevice, setSelectedDevice } = useSelectedDevice();
  const { start, end } = useSelectedTimeRange();
  const [stats, setStats] = useState<Timeseries | null>(null);

  const { getDataForThing } = useDataApi();

  useEffect(() => {
    getDataForThing(device.siteId, device.thingId, start, end)
      .then(convertToTimeseries)
      .then(setStats)
      .catch((error) => {
        console.error(error);
      });
  }, [device, start, end]);

  const header = (
    <div className="flex w-full px-4 pt-2 justify-between items-center ">
      <div className="flex gap-2 justify-start items-center truncate">
        <MeterIcon className="flex-none" />
        <div className="shrink">
          <div className="text-footnote text-space50">{device.thingName}</div>
          <div className="text-detail text-space70">
            {device.thingDescription}
          </div>
        </div>
      </div>

      {/* Status? */}
      {/* Alerts */}
    </div>
  );

  return (
    <div
      className={`bg-white rounded-md elevation-1 cursor-pointer hover:-translate-y-0.5 focus:translate-y-0.5 transition-colors transition-transform transition-shadow hover:shadow-md w-[160px] flex-col justify-start items-start gap-2 flex ${selectedDevice === device ? "outline outline-2 outline-offset-2 outline-blue50" : ""}`}
      onClick={() =>
        setSelectedDevice(selectedDevice === device ? null : device)
      }
    >
      {header}
      <div className="w-full flex-col justify-start items-start gap-px flex"></div>
    </div>
  );
};

const MeterDeviceTile = ({ device }: { device: Thing }) => {
  const { selectedDevice, setSelectedDevice } = useSelectedDevice();
  const { start, end } = useSelectedTimeRange();
  const [stats, setStats] = useState<Timeseries | null>(null);

  const { getDataForThing } = useDataApi();

  useEffect(() => {
    getDataForThing(device.siteId, device.thingId, start, end)
      .then(convertToTimeseries)
      .then(setStats)
      .catch((error) => {
        console.error(error);
      });
  }, [device, start, end]);

  const header = (
    <div className="flex w-full px-4 pt-2 justify-between items-center ">
      <div className="flex gap-2 justify-start items-center truncate">
        <MeterIcon className="flex-none" />
        <div className="shrink">
          <div className="text-footnote text-space50">{device.thingName}</div>
          <div className="text-detail text-space70">
            {device.thingDescription}
          </div>
        </div>
      </div>

      {/* Status? */}
      {/* Alerts */}
    </div>
  );

  // TODO: remove this, it was for a demo
  const hardcodedMaximum = 1500;

  const statsView = (
    <>
      <ProgressBar
        value={stats?.summary?.fwd ?? "-"}
        percentage={stats?.summary?.fwd / hardcodedMaximum}
        label="Forward"
        unit="kWh"
      />
      <ProgressBar
        value={stats?.summary?.reverse ?? "-"}
        percentage={stats?.summary?.reverse / hardcodedMaximum}
        label="Reverse"
        unit="kWh"
      />
      <ProgressBar
        value={stats?.summary?.net ?? "-"}
        percentage={stats?.summary?.net / hardcodedMaximum}
        label="Net"
        unit="kWh"
      />
    </>
  );

  return (
    <div
      className={`bg-white rounded-md elevation-1 cursor-pointer hover:-translate-y-0.5 focus:translate-y-0.5 transition-colors transition-transform transition-shadow hover:shadow-md w-[160px] flex-col justify-start items-start gap-2 flex ${selectedDevice === device ? "outline outline-2 outline-offset-2 outline-blue50" : ""}`}
      onClick={() =>
        setSelectedDevice(selectedDevice === device ? null : device)
      }
    >
      {header}
      <div className="w-full pb-2 flex-col justify-start items-start gap-px flex">
        {statsView}
      </div>
    </div>
  );
};

const ChargerOrBatteryDeviceTile = ({ device }: { device: Thing }) => {
  const { selectedDevice, setSelectedDevice } = useSelectedDevice();
  const { start, end } = useSelectedTimeRange();
  const [stats, setStats] = useState({});
  const { user } = useAuth();

  const isCharger = device.thingType == "Charger";

  const { getChargerSummaryStats } = useChargerTimeseries();
  const { getBatterySummaryStats } = useBatteryTimeseries();

  useEffect(() => {
    if (!user) return;
    if (isCharger) {
      getChargerSummaryStats(
        user.partnerId,
        device.thingName,
        device.siteId,
        start,
        end,
      ).then(setStats);
    } else {
      getBatterySummaryStats(
        user.partnerId,
        device.thingName,
        device.siteId,
        start,
        end,
      ).then(setStats);
    }
  }, [device, start, end, isCharger, user?.partnerId]);

  const header = (
    <div className="flex w-full px-4 pt-2 justify-between items-center ">
      <div className="flex gap-2 justify-start items-center">
        {isCharger ? <ChargersIcon /> : <BatteriesIcon />}
        <div className="text-footnote text-space50">{device.thingName}</div>
      </div>
      {/* Status? */}
      {/* Alerts */}
    </div>
  );

  const statsView = isCharger ? (
    <>
      <ProgressBar
        value={(stats as ChargerSummaryStats).dispensed ?? 0}
        percentage={0.798}
        unit="kWh"
        label="Discharged"
      />
      <ProgressBar
        value={(stats as ChargerSummaryStats).scheduled ?? 0}
        percentage={0.798}
        unit="kWh"
        label="Scheduled"
      />
      <ProgressBar
        value={(stats as ChargerSummaryStats).possible ?? 0}
        percentage={1}
        unit="kWh"
        label="Possible"
        colorOverride={Color.Gray}
      />
      <ProgressBar
        value={(stats as ChargerSummaryStats).drawn ?? 0}
        percentage={0.798}
        unit="kWh"
        label="Drawn"
      />
    </>
  ) : (
    <>
      <ProgressBar
        value={(stats as BatterySummaryStats).stored ?? 0}
        percentage={
          (stats as BatterySummaryStats).stored /
          (stats as BatterySummaryStats).capacity
        }
        label="Stored"
        unit="kWh"
      />
      <ProgressBar
        value={(stats as BatterySummaryStats).health ?? 0}
        percentage={(stats as BatterySummaryStats).health / 100 ?? 0}
        label="Health"
        unit="% SoH"
      />
      <ProgressBar
        value={(stats as BatterySummaryStats).capacity ?? 0}
        percentage={1}
        label="Capacity"
        unit="kWh"
        colorOverride={Color.Gray}
      />
    </>
  );

  return (
    <div
      className={`bg-white rounded-md elevation-1 cursor-pointer hover:-translate-y-0.5 focus:translate-y-0.5 transition-colors transition-transform transition-shadow hover:shadow-md w-[160px] flex-col justify-start items-start gap-2 flex ${selectedDevice === device ? "outline outline-2 outline-offset-2 outline-blue50" : ""}`}
      onClick={() =>
        setSelectedDevice(selectedDevice === device ? null : device)
      }
    >
      {header}
      <div className="w-full pb-2 flex-col justify-start items-start gap-px flex">
        {stats && statsView}
      </div>
    </div>
  );
};

export default DeviceTile;
