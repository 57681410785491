import { fetchFromTimestream } from "api";
import { useAuthFetch } from "context/AuthContext";
import { Dayjs, dayjs } from "utils/dayjs";

import { NO_DATA_ROW, formatDate } from "./utils";

export const useChargerTimeseries = () => {
  const { authFetch } = useAuthFetch();

  const getAlertsForCharger = (
    partnerId: string,
    deviceName: string, // use attributes.FriendlyName
    siteId: string,
    start: Dayjs,
    end: Dayjs,
  ): Promise<[]> => {
    const formattedDayStart = formatDate(start.startOf("day"));
    const table = `"${partnerId}"."${siteId}_Data"`;
    const timeStart = formatDate(start);
    const timeEnd = formatDate(end);

    return fetchFromTimestream(
      `SELECT
        time,
        date_diff('minute', '${formattedDayStart}', time) / 60.0,
        status
      FROM ${table}
      WHERE status != 0
      AND DeviceName = '${deviceName}'
      AND time BETWEEN '${timeStart}' AND '${timeEnd}'`,
      authFetch,
    )
      .then((data) => data.rows)
      .then((rows) =>
        rows.map((alert) => {
          const [time, x, status] = alert.data.map(
            (value) => value.scalarValue,
          );

          const state =
            status === "1" ? "warning" : status === "2" ? "critical" : "";
          const message =
            status === "1"
              ? "Charger Faulted"
              : status === "2"
                ? "Charger Offline"
                : "";

          return {
            type: "charger",
            state: state,
            message: message,
            infoTop: deviceName,
            infoBottom: formatDate(dayjs(time)),
            x: x,
          };
        }),
      );
  };

  const getChargerSummaryStats = (
    partnerId: string,
    deviceName: string, // use attributes.FriendlyName
    siteId: string,
    start: Dayjs,
    end: Dayjs,
  ): Promise<ChargerSummaryStats> => {
    const table = `"${partnerId}"."${siteId}_Data"`;
    const timeStart = formatDate(start);
    const timeEnd = formatDate(end);

    return fetchFromTimestream(
      `SELECT
        SUM(EnergyUsedWh) / 1000.0 AS TotalEnergyUsedkWh,
        SUM(GridDrawnWh) / 1000.0 AS TotalGridDrawnkWh
      FROM (
        SELECT
            chargeCutoff * date_diff('second', LAG(time) OVER (ORDER BY time), time) / 3600.0 AS EnergyUsedWh,
            gridDrawOff * date_diff('second', LAG(time) OVER (ORDER BY time), time) / 3600.0 AS GridDrawnWh
        FROM ${table}
        WHERE DeviceName = '${deviceName}'
        AND time BETWEEN '${timeStart}' AND '${timeEnd}'
      )`,
      authFetch,
    )
      .then((data) => data.rows[0]?.data ?? NO_DATA_ROW)
      .then(([used, drawn]) => {
        return {
          dispensed: Math.floor(used.scalarValue),
          scheduled: 0,
          possible: Math.floor(used.scalarValue),
          drawn: Math.floor(drawn.scalarValue),
        };
      });
  };

  const getChargerTimeseries = (
    partnerId: string,
    deviceName: string, // use attributes.FriendlyName
    siteId: string,
    start: Dayjs,
    end: Dayjs,
  ): Promise<ChargerTimeseries[]> => {
    const table = `"${partnerId}"."${siteId}_Data"`;
    const timeStart = formatDate(start);
    const timeEnd = formatDate(end);

    return fetchFromTimestream(
      `SELECT
        date_diff('minute', '${timeStart}', time) / 60.0,
        chargeCutoff / 1000.0,
        gridDrawOff / 1000.0
      FROM ${table}
      WHERE DeviceName = '${deviceName}'
      AND time BETWEEN '${timeStart}' AND '${timeEnd}'`,
      authFetch,
    )
      .then((data) => data.rows ?? [])
      .then((rows) => {
        return rows.map(({ data }) => {
          const [x, discharged, drawn] = data;
          return {
            time: x.scalarValue,
            discharged: discharged.scalarValue,
            drawn: drawn.scalarValue,
          };
        });
      });
  };

  return {
    getAlertsForCharger,
    getChargerSummaryStats,
    getChargerTimeseries,
  };
};

export type ChargerSummaryStats = {
  dispensed: number;
  scheduled: number;
  possible: number;
  drawn: number;
};

export type ChargerTimeseries = {
  time: number;
  discharged: number;
  drawn: number;
};
