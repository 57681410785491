import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { usePlacesApi } from "api/ingestion/places.ts";
import { SiteTile } from "components/sites/SiteTile.tsx";
import { FleetTile } from 'components/fleets/FleetTile';
import AddSiteModal from "./AddSiteModal";
import { MapView } from "./mapView";

const LocationSelection = ({ locationType }) => {
  const [locations, setLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentType, setCurrentType] = useState(null);

  const { getSites, getFleets, addSite } = usePlacesApi();

  useEffect(() => {
    setIsLoading(true);
    setCurrentType(null);
    const fetchLocations = locationType === 'site' ? getSites : getFleets;
    fetchLocations().then(data => {
      console.log(`Fetched ${locationType} data:`, data);
      setLocations(data);
      setIsLoading(false);
      setCurrentType(locationType);
    }).catch(error => {
      console.error(`Error fetching ${locationType} data:`, error);
      setIsLoading(false);
    });
  }, [locationType]);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleAddSite = (siteData) => {
    return addSite(siteData).then((result) => {
      if (result.success) {
        return { success: true };
      } else {
        return { success: false, error: result.error };
      }
    });
  };

  const renderLocationTile = (location) => {
    if (locationType === 'site') {
      return <SiteTile site={location} />;
    } else {
      return <FleetTile fleet={location} />;
    }
  };

  const getLocationId = (location) => {
    return locationType === 'site' ? location.siteId : location.fleetId;
  };

  if (isLoading || currentType !== locationType) {
    return (
      <div className="flex w-full min-h-screen items-center justify-center">
        <div className="text-lg font-medium text-gray-600">Loading {locationType} data...</div>
      </div>
    );
  }

  const map = (
    <div className="bg-white rounded-md shadow border border-zinc-300 h-screen">
      <MapView locations={locations} />
    </div>
  );

  const locs = locations.map((location) => (
    <NavLink key={getLocationId(location)} to={`/${locationType}s/${getLocationId(location)}`}>
      {renderLocationTile(location)}
    </NavLink>
  ));

  return (
    <div className="min-h-screen grow">
      <div className="flex w-full min-h-screen">
        <div className="flex-1">
          <div className="flex justify-between items-center pt-4 pb-5">
            <div className="text-heading1 text-space50">
              {locationType === 'site' ? 'Sites' : 'Fleets'} / All
            </div>
            {locationType === 'site' && (
              <button
                className="px-3.5 py-2 rounded-full justify-end items-center gap-1 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue90 focus:ring-blue90 flex border border-space80 hover:bg-gray95 text-space70"
                onClick={handleOpenModal}
              >
                + Add Site
              </button>
            )}
          </div>
          <div className="flex flex-col lg:flex-row gap-4 mr-4 mb-4">
            <div className="w-full lg:w-1/2">{locs}</div>
            <div className="w-full lg:w-1/2">{map}</div>
          </div>
        </div>
      </div>
      {locationType === 'site' && (
        <AddSiteModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onAddSite={handleAddSite}
        />
      )}
    </div>
  );
};

export default LocationSelection;