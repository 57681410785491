import React, { useState, useEffect } from 'react';
import { usePlacesApi } from "api/ingestion/places.ts";
import { useThingsApi } from "api/ingestion/things.ts";
import { DeviceDetail, EnergyCard, NetworkMap, StatusAlert, SkyportsChargerStatus } from "components";
import { HorizontalTimeseriesChart } from "components/charts/horizontalTimeSeries.tsx";
import { SelectedDeviceProvider, useSelectedDevice } from "context/SelectedDeviceContext.tsx";
import { SelectedTimeRangeProvider, TimeRangeSelector, useSelectedTimeRange } from "context/SelectedTimeRangeContext.tsx";
import { NavLink, useParams } from "react-router-dom";


// Updated mock data
const MOCK_FLEET = {
  latitude: 40,
  longitude: 40,
  address: 'mock address',
}

const MOCK_BATTERY_DEVICES = [
  { thingId: 'battery1', thingName: 'Battery001', latitude: 40.7140, longitude: -74.0050 },
  { thingId: 'battery2', thingName: 'Battery002', latitude: 40.7160, longitude: -74.0080 },
];

const MOCK_ALERTS = {
  batteryAlerts: {
    BAT002: [{ type: 'Low Charge', message: '[MOCK] Battery 2 is below 20% charge' }],
  },
  chargerAlerts: {
    CHG003: [{ type: 'Maintenance Required', message: '[MOCK] Charger 3 needs maintenance' }],
  },
};

const MOCK_ENERGY_CARD_STATS = [
  { value: 5000, units: 'kWh', label: 'Drawn from Grid', trend: 2 },
  { value: 3500, units: 'kWh', label: 'Dispensed to Vehicles', trend: 5 },
  { value: 1500, units: 'kWh', label: 'Currently Stored', trend: -1 },
  { value: 2000, units: 'kWh', label: 'Forecasted remaining Demand', trend: 0 },
];

const MOCK_TIMESERIES_DATA = {
  values: [
    { time: new Date('2023-01-01T00:00:00Z'), net: 100 },
    { time: new Date('2023-01-01T01:00:00Z'), net: 120 },
    { time: new Date('2023-01-01T02:00:00Z'), net: 110 },
    { time: new Date('2023-01-01T03:00:00Z'), net: 130 },
    { time: new Date('2023-01-01T04:00:00Z'), net: 140 },
    { time: new Date('2023-01-01T05:00:00Z'), net: 135 },
  ],
  summary: {
    net: 735,
  },
};

const PageContent = ({ fleetId }) => {
  const { getFleetDetail } = usePlacesApi();
  const { getThingsFromFleet } = useThingsApi();

  const [fleet, setFleet] = useState(null);
  const [batteries, setBatteries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { start, end } = useSelectedTimeRange();
  const [isDownloadingAggregateData, setIsDownloadingAggregateData] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      getFleetDetail(fleetId),
      getThingsFromFleet(fleetId)
    ]).then(([fleetData, thingsData]) => {
      setFleet(fleetData);
      setBatteries(thingsData.filter(thing => thing.thingType === "Battery"));
      setIsLoading(false);
    }).catch((err) => {
      console.error("Error fetching fleet data:", err);
      setIsLoading(false);
    });
  }, [fleetId]);

  const handleDownloadAggregateReport = async () => {
    setIsDownloadingAggregateData(true);
    // Simulate download
    setTimeout(() => {
      setIsDownloadingAggregateData(false);
      alert('Download complete!');
    }, 2000);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex h-full">
      <div className="grow h-screen overflow-y-scroll">
        <div className="flex pt-4 pb-3 justify-between">
          <div className="text-heading1 text-space50 flex items-center">
            <NavLink to="/fleets">Fleets</NavLink>/{fleet.fleetName}
            {/* <button onClick={() => setIsEditModalOpen(true)} className="px-3.5 py-2 rounded-full justify-end items-center gap-1 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue90 focus:ring-blue90 flex border border-space80 hover:bg-gray95 text-space70">
              <FaEdit className="inline-block text-gray-500 hover:text-gray-700" />Edit Fleet
            </button>
            <button className="px-3.5 py-2 rounded-full justify-end items-center gap-1 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue90 focus:ring-blue90 flex border border-space80 hover:bg-gray95 text-space70" onClick={() => setIsModalOpen(true)}>
              <FaPlus className="inline-block text-gray-500 hover:text-gray-700" />Add Fleet
            </button> */}
          </div>
          <div className="flex items-center gap-2">
            <button onClick={handleDownloadAggregateReport} disabled={isDownloadingAggregateData} className="px-3.5 py-2 rounded-full justify-end items-center gap-1 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue90 focus:ring-blue90 flex border border-space80 hover:bg-gray95 text-space70">
              <div className="text-xs font-medium leading-[14px]">
                {isDownloadingAggregateData ? 'Downloading...' : 'Download Aggregate Report'}
              </div>
            </button>
            <TimeRangeSelector />
          </div>
        </div>
        <hr/>
        <div className="my-4"> 
          <h2 className="text-xl font-semibold">{fleet.fleetName}</h2> 
          <span className="text-gray-600">{MOCK_FLEET.address}</span>
          <br/> 
          <span className="text-gray-600">{MOCK_FLEET.longitude},{MOCK_FLEET.latitude}</span>
        </div>

        <div className="flex space-x-4 mb-4 h-[300px]">
          <Tile className="w-1/3 p-4">
            <StatusAlert batteryAlerts={MOCK_ALERTS.batteryAlerts} chargerAlerts={MOCK_ALERTS.chargerAlerts} />
          </Tile>
          <Tile className="w-2/3">
            <NetworkMap
              latitude={fleet.latitude || 0}
              longitude={fleet.longitude || 0}
              batteryDevices={batteries}
              chargerDevices={[]}
              meterDevices={[]}
            />
          </Tile>
        </div>

        <div className="flex space-x-4 mb-4">
          <Tile className="w-full">
            <HorizontalTimeseriesChart timeseries={MOCK_TIMESERIES_DATA} />
          </Tile>
        </div>      

        <div className="flex space-x-4 mb-4 h-[200px]">
          <Tile className="w-full">
            <EnergyCard energyCardStats={MOCK_ENERGY_CARD_STATS} />
          </Tile>
        </div>

        {/* New section for displaying battery status */}
        <div className="flex space-x-4 mb-4">
          <Tile className="w-full">
            <SkyportsChargerStatus
              batteries={batteries}
              chargers={[]}
              meters={[]}
            />
          </Tile>
        </div>
      </div>

      <DeviceDetailWrapper />
    </div>
  );
};

const FleetDetail = () => {
  const { fleetId } = useParams();
  return (
    <SelectedTimeRangeProvider>
      <SelectedDeviceProvider>
        <PageContent fleetId={fleetId} />
      </SelectedDeviceProvider>
    </SelectedTimeRangeProvider>
  );
};

export default FleetDetail;

const Tile = ({ className = "", children }) => {
  return (
    <div className={`bg-white rounded-md shadow border border-gray90 ${className}`}>
      {children}
    </div>
  );
};

const DeviceDetailWrapper = () => {
  const { selectedDevice, setSelectedDevice } = useSelectedDevice();
  return (
    <DeviceDetail
      selectedDevice={selectedDevice}
      setSelectedDevice={setSelectedDevice}
    />
  );
};