import { useState, useEffect } from 'react';

export const EditSiteModal = ({ isOpen, onClose, site, onSave }) => {
  const [formData, setFormData] = useState({
    siteName: site.siteName,
    longitude: site.longitude,
    latitude: site.latitude,
    address: site.address,
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (isOpen) {
      setFormData({
        siteName: site.siteName,
        longitude: site.longitude,
        latitude: site.latitude,
        address: site.address,
      });
      setErrors({});
    }
  }, [isOpen, site]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const validateForm = () => {
    let newErrors = {};

    if (!formData.siteName.trim()) {
      newErrors.siteName = 'Site name is required';
    }

    if (isNaN(formData.longitude) || formData.longitude < -180 || formData.longitude > 180) {
      newErrors.longitude = 'Longitude must be a number between -180 and 180';
    }

    if (isNaN(formData.latitude) || formData.latitude < -90 || formData.latitude > 90) {
      newErrors.latitude = 'Latitude must be a number between -90 and 90';
    }

    if (!formData.address.trim()) {
      newErrors.address = 'Address is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const success = await onSave(formData);
      if (success) {
        onClose();
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed z-20 inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="flex items-center justify-center min-h-screen">
        {/* Background overlay */}
        <div 
          className="fixed inset-0 transition-opacity" 
          aria-hidden="true"
          onClick={onClose}
        >
          <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>

        {/* Modal panel */}
        <div 
          className="bg-white p-6 rounded-lg max-w-md w-full relative z-50"
          role="dialog" 
          aria-modal="true" 
          aria-labelledby="modal-headline"
        >
          <h2 className="text-xl mb-4" id="modal-headline">Edit Site</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="siteName" className="block text-gray-700 text-sm font-bold mb-2">Site Name</label>
              <input
                type="text"
                id="siteName"
                name="siteName"
                value={formData.siteName}
                onChange={handleChange}
                className={`w-full border rounded px-2 py-1 ${errors.siteName ? 'border-red-500' : ''}`}
              />
              {errors.siteName && <p className="text-red-500 text-sm mt-1">{errors.siteName}</p>}
            </div>
            <div className="mb-4">
              <label htmlFor="longitude" className="block text-gray-700 text-sm font-bold mb-2">Longitude</label>
              <input
                type="number"
                id="longitude"
                name="longitude"
                value={formData.longitude}
                onChange={handleChange}
                step="any"
                className={`w-full border rounded px-2 py-1 ${errors.longitude ? 'border-red-500' : ''}`}
              />
              {errors.longitude && <p className="text-red-500 text-sm mt-1">{errors.longitude}</p>}
            </div>
            <div className="mb-4">
              <label htmlFor="latitude" className="block text-gray-700 text-sm font-bold mb-2">Latitude</label>
              <input
                type="number"
                id="latitude"
                name="latitude"
                value={formData.latitude}
                onChange={handleChange}
                step="any"
                className={`w-full border rounded px-2 py-1 ${errors.latitude ? 'border-red-500' : ''}`}
              />
              {errors.latitude && <p className="text-red-500 text-sm mt-1">{errors.latitude}</p>}
            </div>
            <div className="mb-4">
              <label htmlFor="address" className="block text-gray-700 text-sm font-bold mb-2">Address</label>
              <input
                type="text"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                className={`w-full border rounded px-2 py-1 ${errors.address ? 'border-red-500' : ''}`}
              />
              {errors.address && <p className="text-red-500 text-sm mt-1">{errors.address}</p>}
            </div>
            <div className="flex justify-end space-x-4">
              <button type="submit" className="px-3.5 py-2 rounded-full border border-space80 bg-blue90 justify-end items-center gap-1 cursor-pointer flex">Save</button>
              <button type="button" onClick={onClose} className="px-3.5 py-2 rounded-full border border-space80 justify-end items-center gap-1 cursor-pointer flex">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};